import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import VueVirtualScroller from "vue-virtual-scroller";

import Vue3Toastify from "vue3-toastify";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

import messages from "@intlify/unplugin-vue-i18n/messages";

import { i18nVue } from "laravel-vue-i18n";
import { createI18n } from "vue-i18n";

import { usePwa } from "./Composables/usePwa";

const { createPwa } = usePwa();

Object.keys(messages).forEach((key) => {
    delete Object.assign(messages, {
        [key.replace("php_", "")]: messages[key],
    })[key];
});

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}
createPwa();
createInertiaApp({
    progress: {
        // The delay after which the progress bar will appear
        // during navigation, in milliseconds.

        // The color of the progress bar.
        color: "#29d",

        // Whether to include the default NProgress styles.
        includeCSS: true,

        // Whether the NProgress spinner will be shown.
        showSpinner: true,
    },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const i18n = createI18n({
            legacy: false,
            locale: "en",
            fallbackLocale: "it",
            globalInjection: true,
            messages,
        });
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(i18nVue, {
                resolve: async (lang) => {
                    const langs = import.meta.glob("../../lang/*.json");
                    console.log(langs);
                    return await langs[`../../lang/${lang}.json`]();
                },
            })
            .use(i18n)
            .use(ZiggyVue)
            .use(VueVirtualScroller)
            .use(Vue3Toastify, {
                autoClose: 6000,
            })
            .use(FloatingVue)
            .mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
